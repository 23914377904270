<template>
    
    <div class="home">
        <Header/>
        <div class="body">
            <div class="main">
                <div class="main_banner">
                    <van-swipe :autoplay="autoplayTime" :height="220" show-indicators:true >
                        <van-swipe-item  :default="item.img"  v-for="(item, index) in bigBannerList" :key="index"  @touchstart="onTouchStart">
                            <video style="z-index: 1;" :id="index" class="videoStyle myVideo" :class="index" loop controls preload="metadata" type="video/mp4" :ref="'testVideo' + index" v-if="item.play_type == 2" :src='item.img' @play="onPlay" @pause="onPause" ></video>
                        </van-swipe-item>
                    </van-swipe>
                </div>

                <div class="main_bottom">
                    <div class="recommendPage" style="height:220px;width:100%">
                        <swiper :options="swiperOption" ref="mySwiper" v-if="samllBannerList.length">
                            <swiper-slide v-for="(item, index) in samllBannerList" :key="index"><a :href="item.url"><img :src="item.img"></a></swiper-slide>
                            <div class="swiper-button-prev" slot="button-prev"></div>
                            <div class="swiper-button-next" slot="button-next"></div>
                        </swiper>
                    </div>
                </div>
                <div class="download_button">
                    <div class="download">
                        <a :href="updateLogsList.newVersion.ios_link" @click="iosDownload()">
                            <div class="button_ios">
                                <i></i>
                                <div>IOS下載</div>
                            </div>
                        </a>
                        <a :href="updateLogsList.newVersion.android_link" @click="androidDownload()">
                            <div class="button_android">
                                <i></i>
                                <div>安卓下載</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="foot">
            <div class="foot_bg">
                <a class="jumpUrl" :href="jumpUrl">
                <div class="foot_left" v-show="false">
                    <img src="../assets/image/payBanner.png" alt="">
                </div>
                </a>
                <div class="foot_right">
                    <div class="code clearFix"><img src="../assets/image/line.jpg" alt=""></div>
                    <div class="join"><div>加入官方LINE交友群</div><div>定时免费派送奖励</div></div>
                </div>
            </div>
        </div>
        <FootNav  :activeIndex="active"/>
    </div>

</template>

<script>

import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import Header from "../components/Header.vue";
import FootNav from "../components/FootNav.vue";


export default {
  components: {
    swiper,
    swiperSlide,
    Header,
    FootNav,
  },
  data() {
    return {
        active:0,
        autoplayTime:3000,
        jumpUrl:'',
        icon: {
            active: '@/assets/image/play.png',
            inactive: '@/assets/image/play_hover.png'
        },
        bigBannerType:1,
        samllBannerType:2,
        bigBannerList:[],
        samllBannerList:[],
        type:1,
        iosType:'ios',
        androidType:'android',
        updateLogsList:
        {
            newVersion:{
                ios_link:"",
                android_link:""
            },
        },
        swiperBig: {
            loop: true,
            autoplay: {
                delay: 3000,
                stopOnLastSlide: false,
                disableOnInteraction: false
            },
        },
        swiperOption: {
            loop: true,
            observer: true, 
            observeParents: true,
            watchSlidesProgress: true,
            centeredSlides: true,
            slidesPerView: 3,
            freeMode: true,
            spaceBetween: 0,
            slideToClickedSlide:true,
            mousewheel:true,
            autoplay: {
                delay: 3000,
                stopOnLastSlide: false,
                centeredSlides: true,
                disableOnInteraction: false
            },
            // 设置点击箭头
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
    
  },
    updated(){
        this.$nextTick(() => {
            this.firstAutoPlay()
        })
    },

  created() {
    this.getBigBanner()
    this.getSmallBanner()
    this.getUpdateLogs()
    this.getDeviceModel()
  },
  methods:{
    getDeviceModel(){
        if(this.env == 'test'){
            this.jumpUrl = "http://192.168.1.97:88"
        }
        if(this.env == 'pro'){
            this.jumpUrl = "https://landx.boloplay.com/#/pay"
        }
        if(this.env == 'local'){
            this.jumpUrl = "http://localhost:8080/#/pay"
        }

        var u = navigator.userAgent;
        this.isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    },
    getBigBanner(){
        this.service.post('Home/banner',{type: this.bigBannerType})
            .then((res) => {
            this.bigBannerList = res.data.data
        }) 
    },
    getSmallBanner(){
        this.service.post('Home/banner',{type: this.samllBannerType})
        .then((res) => {
            this.samllBannerList = res.data.data
        }) 
    },
    getUpdateLogs(type){
        this.service.post('Home/updateLogs',{type : type})
        .then((res) => {
            if(res.data.status == 1){
                this.updateLogsList = res.data.data
            }
        }) 
    },
    iosDownload(){
        this.service.post('HomeLog/download',{type : this.iosType})
        .then(() => {

        }) 
    },
    androidDownload(){
        this.service.post('HomeLog/download',{type : this.androidType})
        .then(() => {
  
        }) 
    },
    onPlay() {
        this.autoplayTime = 0
    },
    onPause() {
        this.autoplayTime = 3000
    },
    onTouchStart(){
        this.autoplayTime = 3000
    },
    firstAutoPlay(){
        this.$nextTick(() => {
            eval(this.$refs.testVideo0)[0].muted = true;
            eval(this.$refs.testVideo0)[0].play();
        })
    },


  }
};
</script>

<style>
.jumpUrl{
    display: block;
}
.van-swipe-item .videoStyle{
    width: 100%;
    height: 100%;
    border-radius: 19px;
    -webkit-border-radius: 19px;
    -ms-border-radius: 19px;
    overflow: hidden;
    object-fit: cover;

}
.van-swipe{
    overflow: hidden;
}
.van-swipe .van-swipe-item{
    overflow: hidden;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
    width: 30px;
    height: 30px;
    font-size: 30px;
    border-radius: 30px;
    background-size: 30px 30px;
    margin-top: -10px;
    background-image: url('../assets/image/right.png');
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next{
    width: 30px;
    height: 30px;
    border-radius: 30px;
    font-size: 30px;
    background-size: 30px 30px;
    margin-top: -10px;
    background-image: url('../assets/image/left.png');
}
.card{
    width: 100%;
    height: 100%;
}
.head{
    width: 100%;
    height: 56px;
    background-color: blue;
    position: relative;
    background:linear-gradient(to bottom,#FF923D,#FF743D);
    border-bottom:2px solid #FFEE78;

}
.head_left{
    width:56px;
    height: 56px;
    position: absolute;
    left: 0;
}

.item_daohang img{
    width: 30px;
    height: 30px;
    margin-top: 13px;
    margin-left: 10px;
}
.head_right{
    width: 100%;
    float: right;
    left: 0;
    position: absolute;
    border-bottom: 2px;
}

.head_logo{
    width: 65px;
    height :45px;
    margin: 0px auto;
    margin-top: 5px;
}
.head_logo img{
    width: 65px;
    height :45px;
}
.home{
    margin-bottom: 48px;
    height: 100%;
}
body{
    background-image: url('../assets/image/bg.png');
}
.body{
    height: 100%;
    padding: 0px 10px;
}
.main{
    width: 100%;
}
.main_banner{
    z-index: 4;
    width: 100%;
    height: 100%;
    border-radius: 19px;
    padding-bottom: 10px;
    overflow: hidden;
    margin-top: 15px;
}
.van-swipe{
    overflow: hidden;
}
.van-swipe-item ,.van-swipe-item--default1{
    overflow: hidden;
    border-radius: 19px;
}
.van-swipe-item{
    overflow: hidden;
}
.van-swipe-item img{
    width: 100%;
    height: 100%;
    border-radius: 19px;
}
.van-swipe__indicator{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background:rgba(255,255,255,50)
}
.van-swipe{
    width: 100%;
    height: 100%;
    border-radius: 19px;
    border: 2px solid #FFF99E ;
    box-shadow: 1px 0px 4px 0px #ba5701;
}
.van-swipe__indicator--active{
    color: #ffffff;
}
.main_bottom{
    width: 100%;
    height: 100%;
}

.swiper-container {
    width: 100%;
    height: 100%;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: inline-block;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.8)!important;
    -webkit-transform: scale(0.8);
    border: 1px solid #FFF99E;
    border-radius: 13px;
    box-shadow: 4px 0px 9px 0px #8F0000;
}
.swiper-slide-active,.swiper-slide-duplicate-active{
    transform: scale(1)!important;
}
.swiper-slide img{
    height: 100%;
    width: 100%;
    border-radius: 13px;
}
.download_button{
    margin-top: 15px;
    height: 80px;
    width: 100%;
}
.download{
    height: 40px;
    line-height: 40px;
}
.download_button .button_ios{
    border: 2px solid #FFF99E;
    border-radius: 100px;
    background:linear-gradient(to bottom,#FFBB38,#FA793A);
    width: 40%;
    float: left;
    margin-left: 5%;
    margin-right: 10%;
    color: #ffffff;
}
.download_button .button_android{
    border: 2px solid #FFF99E;
    border-radius: 100px;
    width: 40%;
    color: #ffffff;
    background:linear-gradient(to bottom,#98E05F,#0ECD9C);
    float: left;
}
.download_button .button_ios i{
    width: 20%;
    height: 30px;
    float: left;
    background-image:url(../assets/image/ios.png);
    background-repeat: no-repeat;
    background-size: 30px;
    margin-left: 10%;
    margin-top: 5px;
}
.download_button .button_ios div{
    width: 70%;
    float: left;
    font-size: 18px;
}
.download_button .button_android i{
    width: 20%;
    height: 30px;
    float: left;
    margin-left: 10%;
    margin-top: 5px;
    background-image: url(../assets/image/android.png);
    background-repeat: no-repeat;
    background-size: 30px;
}
.download_button .button_android div{
    width: 70%;
    float: left;
    font-size: 18px;
}
.swiper-container{
    padding: 5px 0px;
}
.foot{
    height: 140px;
    width: 100%;
    padding-bottom: 20px;
}
.foot_bg{
    height: 100%;
    padding: 0px 10px;
}
.foot_left{
    width: 67%;
    height: 140px;
    float: left;
}
.foot_left img{
    width: 100%;
    height: 140px;
}
.foot_right{
    width: 30%;
    height: 130px;
    float: right;
    text-align: center;
}
.foot_right .code{
    width: 110px;
    height: 110px;
    float: right;
    border-radius: 10px;
}
.foot_right .code img{
    border-radius: 5px;
    width: 100%;
    height: 100%;
}
.foot_right .join{
    float: right;
    width: 100%;
    font-size: 12px;
    height: 30px;
    line-height: 15px;
    color: #FFF99E;
    text-align: center;
}

.van-tabbar{
    z-index: 5;
    background:linear-gradient(to bottom,#FFBB38,#FA793A);
}
.van-tabbar-item{
    color: #ffffff;
}
.van-tabbar-item--active{
    color: #FFEE78;
    background:linear-gradient(to bottom,#FFEE78,#FA793A);
}
.van-tabbar-item--inactive{
    color: #ffffff;
}
.icon .active{
    color: #FFEE78;
}
.icon .inactive{
    color: #ffffff;
}


</style>

